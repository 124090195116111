import React, { useRef, useEffect, FC } from 'react';

const Wave: FC = () => {
  const canvas = useRef(null);

  useEffect(() => {
    const cvs = canvas.current;
    const ctx = cvs.getContext('2d');
    cvs.width = document.documentElement.clientWidth;
    cvs.height = 80;
    const h = cvs.height;
    const w = cvs.width;

    const xAxis = Math.floor(h / 2);
    const yAxis = 0;

    // 波の描画
    ctx.strokeStyle = '#E8E8E8';
    ctx.lineWidth = 1;

    let t = 0;
    const rate = 3000;
    const now = (new Date()).getTime();

    setInterval(() => {
      t = ((now - (new Date()).getTime()) / rate);
      ctx.clearRect(0, 0, w, h);

      ctx.beginPath();
      let x = t;
      let y = Math.sin(x) / 3;
      ctx.moveTo(yAxis, 100 * y + xAxis);
      for (let i = yAxis; i <= w + 10; i += 10) {
        x = t + (-yAxis + i) / 100 / 3;
        y = Math.sin(x) / 3;
        ctx.lineTo(i, 100 * y + xAxis);
      }

      t = ((now - (new Date()).getTime()) / rate) * 2;
      ctx.moveTo(yAxis, 100 * y + xAxis);
      for (let i = yAxis; i <= w + 10; i += 10) {
        x = t + (-yAxis + i) / 100 / 2;
        y = Math.sin(x) / 3;
        ctx.lineTo(i, 100 * y + xAxis);
      }

      t = ((now - (new Date()).getTime()) / rate) + rate;
      ctx.moveTo(yAxis, 100 * y + xAxis);
      for (let i = yAxis; i <= w + 10; i += 10) {
        x = t + (-yAxis + i) / 100 / 4;
        y = Math.sin(x) / 3;
        ctx.lineTo(i, 100 * y + xAxis);
      }
      ctx.stroke();
    }, 0.1);
  }, []);

  return (
    <>
      <canvas ref={canvas} />
    </>
  );
};

export default Wave;
