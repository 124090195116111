import React from 'react';
import ReactDom from 'react-dom';

import Wave from './components/Wave';
import GalleryCarousel from './components/GalleryCarousel';

import './common';

ReactDom.render(<Wave />, document.getElementById('wave'));
ReactDom.render(<GalleryCarousel />, document.getElementById('gallerySlide'));

