import React, { useState } from 'react';
import { css } from '@emotion/css';

import type { FC } from 'react';

const styles = {
  header: css({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    padding: '15px',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '@media (min-width: 768px)': {
      padding: '20px 50px',
    },
  }),

  headerLogoLink: css({
    display: 'block',
    maxWidth: 100,

    '@media (min-width: 768px)': {
      maxWidth: 215,
    },
  }),

  btn: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 30,
    height: 30,
    backgroundColor: 'transparent',
    border: 0,
    padding: '7px 3px',
    position: 'relative',
    zIndex: 10,
    cursor: 'pointer',

    '& span': {
      height: 2,
      backgroundColor: '#fff',
      width: '100%',
      transition: '.5s ease-in-out',
    },

    '&[aria-expanded="true"]': {

      '& span': {
        backgroundColor: '#444',

        '&:nth-of-type(1)': {
          transform: 'translateY(7px) rotate(-315deg)',
        },

        '&:nth-of-type(2)': {
          opacity: 0,
        },

        '&:nth-of-type(3)': {
          transform: 'translateY(-7px) rotate(315deg)',
        },
      },
    },
  }),

  nav: css({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, .96)',
    transform: 'translateY(-100%)',
    zIndex: 9,
    transition: '.5s ease-in-out',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&[aria-hidden="false"]': {
      transform: 'translateY(0)',
    },
  }),

  menuWrap: css({
    maxHeight: 'calc(100% - 150px)',
    overflowY: 'auto',
  }),

  menu: css({
    margin: '0 0 40px',
  }),

  item: css({
    textAlign: 'center',

    '&:not(:last-of-type)': {
      margin: '0 0 20px',
    },
  }),

  link: css({
    color: '#444',
    textDecoration: 'none',
    fontSize: '1.4rem',

    '@media (min-width: 768px)': {
      fontSize: '2rem',
    },
  }),

  sub: css({
    textAlign: 'center',
  }),

  privacy: css({
    color: '#444',
    textDecoration: 'none',
    fontSize: '1.4rem',
    display: 'inline-block',
    margin: '0 0 15px',

    '@media (min-width: 768px)': {
      fontSize: '1.8rem',
    },
  }),

  sns: css({
    display: 'flex',
    justifyContent: 'center',
  }),

  snsLink: css({
    width: 28,

    '&:nth-of-type(1)': {
      margin: '0 20px 0 0',
    },
  }),
};

const HeaderNav: FC<{ path: string }> = ({ path }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <header className={styles.header}>
        <a className={styles.headerLogoLink} href={`${path}./.`}>
          <h1>
            {
              open
                ? <img src={`${path}images/logo-black.svg`} alt="紺野機業場" width="800" height="151" />
                : <img src={`${path}images/logo.svg`} alt="紺野機業場" width="800" height="151" />
            }
          </h1>
        </a>
        <button className={styles.btn} type="button" onClick={handleOpen} aria-expanded={open}>
          <span />
          <span />
          <span />
        </button>
      </header>
      <nav className={styles.nav} aria-hidden={!open}>
        <div className={styles.menuWrap}>
          <ul className={styles.menu}>
            <li className={styles.item}>
              <a className={styles.link} href={`${path}./.`}>ホーム</a>
            </li>
            <li className={styles.item}>
              <a className={styles.link} href={`${path}silk/`}>KAWAMATA KONNO SILKとは</a>
            </li>
            <li className={styles.item}>
              <a className={styles.link} href={`${path}technology.html`}>紺野機業場の技術</a>
            </li>
            <li className={styles.item}>
              <a className={styles.link} href="https://konnosilk.stores.jp/" target="_blank" rel="noreferrer">商品紹介</a>
            </li>
            <li className={styles.item}>
              <a className={styles.link} href={`${path}company.html`}>会社概要</a>
            </li>
            <li className={styles.item}>
              <a className={styles.link} href={`${path}challenge.html`}>紺野機業場の挑戦</a>
            </li>
            <li className={styles.item}>
              <a className={styles.link} href={`${path}tour.html`}>工場見学</a>
            </li>
            <li className={styles.item}>
              <a className={styles.link} href={`${path}recruit.html`}>採用情報</a>
            </li>
            <li className={styles.item}>
              <a className={styles.link} href={`${path}gallery.html`}>ギャラリー</a>
            </li>
            <li className={styles.item}>
              <a className={styles.link} href={`${path}inquiry.html`}>お問い合わせ</a>
            </li>
          </ul>
          <div className={styles.sub}>
            <a className={styles.privacy} href={`${path}privacy.html`}>プライバシーポリシー</a>
            <div className={styles.sns}>
              <a className={styles.snsLink} href="##" target="_blank">
                <img src={`${path}images/footer-facebook.svg`} alt="" />
              </a>
              <a className={styles.snsLink} href="##" target="_blank">
                <img src={`${path}images/footer-instagram.svg`} alt="" />
              </a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default HeaderNav;
