import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import type { FC } from 'react';
import type { Settings } from 'react-slick';

const settings: Settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,

  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Gallery: FC = () => {
  const slider = useRef(null);

  const handleNext = () => {
    slider.current.slickNext();
  };

  const handlePrev = () => {
    slider.current.slickPrev();
  };

  return (
    <>
      <div className="galleryWrap">
        <div className="gallery">
          <div className="galleryTitleBox">
            <div>
              <h2 className="galleryTitleEn">Gallery</h2>
              <a className="galleryTitleLink" href="gallery.html">作品一覧を見る</a>
            </div>
          </div>
          <div className="gallerySlideWrap">
            <Slider {...settings} ref={slider}>
              <div className="gallerySlide">
                <div className="gallerySlideBox">
                  <div className="gallerySlidePic">
                    <img src="images/index-gallery-01.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="gallerySlide">
                <div className="gallerySlideBox">
                  <div className="gallerySlidePic">
                    <img src="images/index-gallery-02.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="gallerySlide">
                <div className="gallerySlideBox">
                  <div className="gallerySlidePic">
                    <img src="images/index-gallery-03.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="gallerySlide">
                <div className="gallerySlideBox">
                  <div className="gallerySlidePic">
                    <img src="images/index-gallery-01.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="gallerySlide">
                <div className="gallerySlideBox">
                  <div className="gallerySlidePic">
                    <img src="images/index-gallery-02.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="gallerySlide">
                <div className="gallerySlideBox">
                  <div className="gallerySlidePic">
                    <img src="images/index-gallery-03.jpg" alt="" />
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div className="galleryBtns">
        <button className="galleryBtn" type="button" onClick={handleNext} />
        <button className="galleryBtn" type="button" onClick={handlePrev} />
      </div>

    </>
  );
};

export default Gallery;