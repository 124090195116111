import React from 'react';
import ReactDom from 'react-dom';
import Header from './components/Header';
import 'objectFitPolyfill';

const { path } = document.body.dataset;

ReactDom.render(<Header path={path} />, document.getElementById('header'));

objectFitPolyfill();
